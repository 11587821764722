
<template>
  <div class="ele-body">

      <el-tabs v-model="activeName">
        <el-tab-pane label="零钱抽奖活动消费" name="first">
          <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table.where" class="ele-form-search d-flex f-end"
                     @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
              <!-- <el-form-item label="消费金额:" label-width="80px"  class="w-200">
                <el-input v-model="table.where.amount" placeholder="" clearable/>
              </el-form-item> -->
              <el-form-item label="开始时间:" label-width="80px">
                <el-date-picker v-model="table.where.start_time" type="date" placeholder="选择日期" style="width:165px" clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间:" label-width="80px" >
                <el-date-picker v-model="table.where.end_time" type="date" placeholder="选择日期" class="mr-20" style="width:165px" clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
                <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
<!--                <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
                <download class="ele-btn-icon" label="导出"  v-if="permission.includes('sys:consumption.vue:export')" url="adminiaelist/bonusxExpensesExportExcel" :params="exportOrderParams()"/>
              </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
              <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center"/>
                <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                <el-table-column prop="username" label="消费账号"  show-overflow-tooltip width="160"/>
                <el-table-column prop="title" label="消费内容"  show-overflow-tooltip width="240"/>
                <el-table-column prop="amount" label="消费金额(元)"  show-overflow-tooltip width="120"/>
                <el-table-column prop="sur_amount" label="剩余退还金额(元)"  show-overflow-tooltip width="190"/>
                <el-table-column prop="user_money" label="过期零钱(元)"  show-overflow-tooltip width="120"/>
                <el-table-column prop="ex_time" label="零钱有效期(元)"  show-overflow-tooltip width="120"/>
                <el-table-column prop="check_time" label="消费时间"  show-overflow-tooltip min-width="160">
                  <template slot-scope="{row}">{{ row.check_time*1000 | toDateString }}</template>
                </el-table-column>
                <el-table-column prop="check_name" label="状态"  show-overflow-tooltip min-width="100"/>
                <el-table-column label="操作" width="120px" align="center" :resizable="false">
                  <template slot-scope="{row}">
                    <el-link @click="showActive(row)" icon="el-icon-view" type="primary" :underline="false">查看</el-link>
                  </template>
                </el-table-column>
              </template>
            </ele-data-table>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="启动页广告消费" name="second">
          <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table1.where" class="ele-form-search d-flex f-end"
                     @keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
              <el-form-item label="消费金额:" label-width="80px">
                <el-input v-model="table1.where.fee" placeholder="请输入消费金额" class="input163" clearable/>
              </el-form-item>
              <el-form-item label="开始时间:" label-width="80px">
                <el-date-picker  style="width:165px" v-model="table1.where.start_time" type="date" placeholder="选择日期" clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间:" label-width="80px" >
                <el-date-picker style="width:165px" v-model="table1.where.end_time" type="date" placeholder="选择日期" class="mr-20" clearable>
								</el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
                <el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
                <download class="ele-btn-icon" label="导出" url="adminiaelist/adsExpensesExportExcel"
                            :params="exportOrderParams1()"/>
<!--                <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
              </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table1" :config="table1" :choose.sync="choose1" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
              <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center"/>
                <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                <el-table-column prop="username" label="消费账号"  show-overflow-tooltip width="160"/>
                <el-table-column prop="title" label="消费内容"  show-overflow-tooltip width="250"/>
                <el-table-column prop="fee" label="消费金额(元)"  show-overflow-tooltip width="190"/>
                <el-table-column prop="start_time" label="消费时间"  show-overflow-tooltip min-width="100"/>
                <el-table-column prop="status_name" label="状态"  show-overflow-tooltip min-width="100"/>
								<el-table-column label="操作" width="180px" align="center" :resizable="false"  fixed="right">
									<template slot-scope="{row}">
										<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false">查看</el-link>
									</template>
								</el-table-column>
              </template>
            </ele-data-table>
          </el-card>
					<!-- 查看启动广告 -->
					<el-dialog v-dialogDrag title="查看广告内容" :visible.sync="dialogTableVisibleCheck" @closed="editForm={}" width="900px" :destroy-on-close="true"
						custom-class="ele-dialog-form" :lock-scroll="false">
						<el-card shadow="never">
							<el-form :model="editForm" ref="editForm" label-width="150px">
								<div style="display: flex;">
									<div style="width: 100%;padding: 0 30px 0 10px;border-right: 1px solid #C6C6C6;">
										<el-form-item label="添加时间：" prop="create_time">
											{{ editForm.create_time*1000 | toDateString }}
										</el-form-item>
										<el-form-item label="结束时间：" prop="end_time">
											{{ editForm.end_time*1000 | toDateString }}
										</el-form-item>
										<el-form-item label="结束时间：" prop="end_time">
											{{ editForm.end_time*1000 | toDateString }}
										</el-form-item>
										<el-form-item label="广告商公司名：" prop="company_name">
											{{ editForm.company_name }}
										</el-form-item>
										<el-form-item label="负责人姓名：" prop="company_person">
											{{ editForm.company_person }}
										</el-form-item>
										<el-form-item label="负责人电话：" prop="company_phone">
											{{ editForm.company_phone }}
										</el-form-item>
										<el-form-item label="添加方式：" prop="is_admin">
											{{editForm.is_admin==1?'平台添加':'运营商申请添加'}}
										</el-form-item>
										<el-form-item label="申请地区：" prop="set_address">
											{{ editForm.set_address }}
										</el-form-item>
										<el-form-item label="广告类型：" prop="type">
											{{editForm.type==1?'引导页广告':'宣传广告'}}
										</el-form-item>
										<el-form-item label="显示版：" prop="send_app">
											{{editForm.send_app==1?'司机':(editForm.send_app==2?'商家版':(editForm.send_app==3?'用户版':(editForm.send_app==4?'全平台':'跑腿版')))}}
										</el-form-item>
									</div>
									<div style="width: 100%;">
										<el-form-item label="显示地区：" prop="platform">
											{{editForm.platform==1?'全部地区':'当前地区'}}
										</el-form-item>
										<el-form-item label="时长：" prop="day_num">
											{{editForm.day_num}}
										</el-form-item>
										<el-form-item label="金额(元)：" prop="fee">
											{{editForm.fee}}
										</el-form-item>
										<el-form-item label="显示图片：">
											<!-- <img :src="editForm.picture" width="50%" /> -->
											<el-image style="width:90px;height:140px;" :src="editForm.picture"
												fit="cover" :preview-src-list="[editForm.picture]"></el-image>
										</el-form-item>
										<el-form-item label="链接：" prop="url">
											{{editForm.url}}
										</el-form-item>
										<el-form-item label="描述：" prop="title">
											{{editForm.title}}
										</el-form-item>
									</div>
								</div>
							</el-form>
						</el-card>
					</el-dialog>
        </el-tab-pane>
        <el-tab-pane label="新用户送零钱" name="three">
          <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table2.where" class="ele-form-search d-flex f-end"
                     @keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
              <el-form-item label="用户名:" label-width="80px">
                <el-input v-model="table2.where.name" placeholder="请输入用户名" class="input163" clearable/>
              </el-form-item>
              <el-form-item label="手机号:" label-width="65px">
                <el-input v-model="table2.where.phone" placeholder="请输入手机号" class="input163 mr-20" clearable/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="$refs.table2.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
                <el-button @click="(table2.where={})&&$refs.table2.reload()">重置</el-button>
                <download class="ele-btn-icon" label="导出" url="adminiaelist/looseExpensesExportExcel" :params="exportOrderParams2()"/>
<!--                <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
              </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table2" :config="table2" :choose.sync="choose2" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
              <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center"/>
                <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                <el-table-column prop="name" label="用户名称" show-overflow-tooltip min-width="160"/>
                <el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120"/>
                <el-table-column prop="address" label="地区(省市区)" show-overflow-tooltip min-width="220"/>
                <el-table-column prop="price" label="领取金额(元)" show-overflow-tooltip width="120"/>
                <el-table-column prop="create_time" label="领取时间" show-overflow-tooltip min-width="160"/>
              </template>
            </ele-data-table>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="邀请返现活动" name="four">
          <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table3.where" class="ele-form-search d-flex f-end"
                     @keyup.enter.native="$refs.table3.reload()" @submit.native.prevent>
              <el-form-item label="消费金额(元):" label-width="80px">
                <el-input v-model="table3.where.money" placeholder="请输入消费金额" class="input163" clearable/>
              </el-form-item>
              <el-form-item label="开始时间:" label-width="80px">
                <el-date-picker style="width:163px" v-model="table3.where.create_time" type="date" placeholder="选择日期" clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间:" label-width="80px"  >
                <el-date-picker style="width:163px" v-model="table3.where.end_time" type="date" placeholder="选择日期" class="mr-20" clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="$refs.table3.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
                <el-button @click="(table3.where={})&&$refs.table3.reload()">重置</el-button>
                <download class="ele-btn-icon" label="导出" url="adminiaelist/commissionExportExcel" :params="exportOrderParams3()"/>
<!--                <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
              </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table3" :config="table3" :choose.sync="choose3" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
              <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center" />
                <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip />
                <el-table-column prop="username" label="消费账号"  show-overflow-tooltip width="160"/>
                <el-table-column prop="create_time" label="消费时间"  show-overflow-tooltip min-width="150"/>
                <el-table-column prop="money" label="消费金额(元)"  show-overflow-tooltip min-width="120"/>
                <el-table-column prop="l_money" label="退还金额(元)"  show-overflow-tooltip min-width="120"/>
              </template>
            </ele-data-table>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="短信套餐消费" name="five">
          <el-card shadow="never">
            <!-- 数据表格 -->
            <ele-data-table ref="table4" :config="table4" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
                <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center" />
                <el-table-column type="index" :index="index" label="序号" width="60" align="center"  show-overflow-tooltip/>
                <el-table-column prop="username" label="消费账号"  show-overflow-tooltip min-width="120"/>
                <el-table-column prop="title" label="消费内容"  show-overflow-tooltip min-width="120"/>
                <el-table-column prop="price" label="消费金额(元)"  show-overflow-tooltip min-width="120"/>
                <el-table-column prop="create_time" label="消费时间"  show-overflow-tooltip min-width="120" />
              </template>
            </ele-data-table>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="语音套餐消费" name="six">
          <el-card shadow="never">
            <!-- 搜索表单 -->
            <!-- 数据表格 -->
            <ele-data-table ref="table5" :config="table5" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
              <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center" />
                <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                <el-table-column prop="username" label="消费账号"  show-overflow-tooltip min-width="120"/>
                <el-table-column prop="title" label="消费内容"  show-overflow-tooltip min-width="120"/>
                <el-table-column prop="price" label="消费金额(元)"  show-overflow-tooltip min-width="120"/>
                <el-table-column prop="create_time" label="消费时间"  show-overflow-tooltip min-width="120" />
              </template>
            </ele-data-table>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="95128虚拟号消费列表" name="seven">
          <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table7.where" class="ele-form-search d-flex"
                     @keyup.enter.native="$refs.table7.reload()" @submit.native.prevent>
              <el-form-item label="开始时间：" label-width="110px">
                <el-date-picker :picker-options="p1111ickerOptionsStart" style="width:300px"  popper-class="no-atTheMoment" v-model="table7.where.start_time"  value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间：" label-width="110px">
                <el-date-picker :picker-options="pickerOptionsEnd" style="width:300px"  popper-class="no-atTheMoment" v-model="table7.where.end_time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="$refs.table7.reload()" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
                <el-button @click="(table7.where={})&&$refs.table7.reload()">重置</el-button>
              </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table7" :config="table7" :choose.sync="choose7" height="calc(100vh - 315px)" :stripe=true highlight-current-row>
              <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center" />
                <el-table-column type="index" :index="index" label="序号" width="60" align="center" show-overflow-tooltip/>
                <el-table-column prop="set_area" label="地区"  show-overflow-tooltip min-width="120px"/>
                <el-table-column prop="role_name" label="管理员"  show-overflow-tooltip min-width="120px"/>
                <el-table-column prop="real_name" label="真实姓名"  show-overflow-tooltip  min-width="120px"/>
                <el-table-column prop="mobile" label="手机号"  show-overflow-tooltip min-width="120px"/>
                <el-table-column prop="price"  label="消费金额（元 ）"  show-overflow-tooltip  min-width="120px"/>
                <el-table-column prop="create_time"  label="消费时间"  show-overflow-tooltip  min-width="120px"/>
                <el-table-column prop="type_name" label="消费方式"  show-overflow-tooltip min-width="120px"/>
                <el-table-column prop="package_title" label="消费内容"  show-overflow-tooltip min-width="120px"/>
              </template>
            </ele-data-table>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    <!-- 对话框：查看详情 -->
    <el-dialog v-dialogDrag title="查看详情" :visible.sync="dialogFormVisibleViewAddDiscountActivity" :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="formLabelAlign" label-width="100px">
          <el-card shadow="never">
						<div style="display: flex;">
							<div style="width: 360px;">
								<el-form-item label="标题:" class="drop-down-second">
									<span>{{formLabelAlign.title}}</span>
								</el-form-item>
								<!-- <el-form-item label="所属区域:" style="width:800px;margin-right:10px">
									<span>{{formLabelAlign.set_area}}</span>
								</el-form-item> -->
								<el-form-item label="审核:" class="drop-down-second">
									<span><span>{{formLabelAlign.status==0?'未审核':(formLabelAlign.status==1?'通过':'未通过')}}</span></span>
								</el-form-item>
								<el-form-item label="状态:" class="drop-down-second">
									<span>{{formLabelAlign.status==0?'待开启':(formLabelAlign==1?'已开启':(formLabelAlign==2?'已结束':'暂停'))}}</span>
								</el-form-item>
								<el-form-item label="添加时间:">
									<span>{{formLabelAlign.add_time*1000 | toDateString }}</span>
								</el-form-item>
								<el-form-item label="审核时间:">
									<span>{{formLabelAlign.check_time*1000 | toDateString }}</span>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="上线时间:">
									<span>{{formLabelAlign.online_time*1000 | toDateString}}</span>
								</el-form-item>
								<el-form-item label="总金额(元):" class="drop-down-second">
									<span>{{formLabelAlign.amount}}</span>
								</el-form-item>
								<el-form-item label="剩余金额(元):" class="drop-down-second">
									<span>{{formLabelAlign.sur_amount}}</span>
								</el-form-item>
								<el-form-item label="零钱有效期:" class="drop-down-second">
									<span>{{formLabelAlign.ex_time}}</span>
								</el-form-item>
							</div>
						</div>
            <div style="border-bottom:4px solid #e7eaec;margin-top: 30px;"></div>
            <div class="my-headline">奖励</div>
            <el-form-item label="奖项一:" class="span-left">
              <span>￥：{{formLabelAlign.prize1}}</span>
              <span>数量：{{formLabelAlign.prize1_num}}</span>
              <span>名称：{{formLabelAlign.prize1_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize1_type==0?'未中奖':(formLabelAlign.prize1_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
            <el-form-item label="奖项二:" class="span-left">
              <span>￥：{{formLabelAlign.prize2}}</span>
              <span>数量：{{formLabelAlign.prize2_num}}</span>
              <span>名称：{{formLabelAlign.prize2_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize2_type==0?'未中奖':(formLabelAlign.prize2_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
            <el-form-item label="奖项三:" class="span-left">
              <span>￥：{{formLabelAlign.prize3}}</span>
              <span>数量：{{formLabelAlign.prize3_num}}</span>
              <span>名称：{{formLabelAlign.prize3_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize3_type==0?'未中奖':(formLabelAlign.prize3_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
            <el-form-item label="奖项四:" class="span-left">
              <span>￥：{{formLabelAlign.prize4}}</span>
              <span>数量：{{formLabelAlign.prize4_num}}</span>
              <span>名称：{{formLabelAlign.prize4_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize4_type==0?'未中奖':(formLabelAlign.prize4_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
            <el-form-item label="奖项五:" class="span-left">
              <span>￥：{{formLabelAlign.prize5}}</span>
              <span>数量：{{formLabelAlign.prize5_num}}</span>
              <span>名称：{{formLabelAlign.prize5_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize5_type==0?'未中奖':(formLabelAlign.prize5_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
            <el-form-item label="奖项六:" class="span-left">
              <span>￥：{{formLabelAlign.prize6}}</span>
              <span>数量：{{formLabelAlign.prize6_num}}</span>
              <span>名称：{{formLabelAlign.prize6_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize6_type==0?'未中奖':(formLabelAlign.prize6_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
            <el-form-item label="奖项七:" class="span-left">
              <span>￥：{{formLabelAlign.prize7}}</span>
              <span>数量：{{formLabelAlign.prize7_num}}</span>
              <span>名称：{{formLabelAlign.prize7_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize7_type==0?'未中奖':(formLabelAlign.prize7_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
            <el-form-item label="奖项八:" class="span-left">
              <span>￥：{{formLabelAlign.prize8}}</span>
              <span>数量：{{formLabelAlign.prize8_num}}</span>
              <span>名称：{{formLabelAlign.prize8_name}}</span>
              <span>奖品类型：{{formLabelAlign.prize8_type==0?'未中奖':(formLabelAlign.prize8_type==1?'优惠券':'零钱')}}</span>
            </el-form-item>
          </el-card>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import download from '@/components/Download/index'
export default {
  name: "SysUser",
  components: {download},
  data() {
    return {
      table: {url: '/adminiaelist/bonusx_expenses_record', where: {}},//零钱活动抽奖消费
      table1: {url: '/adminiaelist/ads_expenses_record', where: {}},//启动页广告消费列表
      table2: {url: '/adminiaelist/loose_expenses_record', where: {}},//新用户送零钱消费列表
      table3: {url: '/adminiaelist/commission_log', where: {}},//佣金设置消费列表
      table4: {url: '/adminiaelist/sms_log', where: {}},//短信消费列表
      table5: {url: '/adminiaelist/voice_log', where: {}},//语音通话消费列表
      table7: {url: '/VmRechargeOrder/index', where: {}},//95128语音消费记录
      choose: [],  // 表格选中数据
      choose1: [],  // 表格选中数据
      choose2: [],  // 表格选中数据
      choose3: [],  // 表格选中数据
      choose7: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm:{},
      dialogTableVisibleCheck:false,
      dialogTableVisibleEdit:false,
      dialogTableVisibleUrgent:false,
      dialogFormVisibleViewAddDiscountActivity:false,
      provArr:[],
      cityArr:[],
      districtArr:[],
      urgentList:[],
      imageUrl:false,
      headImg:'',
      activeName:'first',
      dialogFormVisibleAddReturnActivity:false,
      formLabelAlign:{},
      row:[{
        state:'开启'
      },
        {
          state:'关闭'
        }]
    }
  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })
  },

  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {
		handlecheck(row){
			this.dialogTableVisibleCheck=true
			this.$http.post('/Marketing/get_ads_info',{id:row.id}).then(res=>{
				this.editForm = res.data.data
			})
		},
    exportOrderParams() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return {ids: selectionIds, ...query}
    },
    exportOrderParams1() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return {ids: selectionIds, ...query}
    },

    exportOrderParams2() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return {ids: selectionIds, ...query}
    },

    exportOrderParams3() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return {ids: selectionIds, ...query}
    },


    showActive(row){
      this.dialogFormVisibleViewAddDiscountActivity=true
      this.formLabelAlign=row
    },
    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''



      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''
      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    pause_start(row){
      if(row.zt==0){
        this.$confirm('确定暂停活动吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/finance/return_money_switch', {id:row.id,zt:0}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: '暂停成功'});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }else if(row.zt==1){
        this.$confirm('确定开启活动?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/finance/return_money_switch', {id:row.id,zt:1}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: '开始成功'});
              this.$refs.table.reload();
              this.pauseStart='暂停'
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    },
    switch_start(row){

      this.$confirm('确定关闭活动吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/finance/return_money_switch', {id:row.id,zt:2}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: '关闭成功'});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })

    },


    //  保存邀请返现活动
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          if(this.pid){
            this.editForm.pid=this.pid
          }
          if(this.cid){
            this.editForm.cid=this.cid
          }
          if(this.aid){
            this.editForm.aid=this.aid
          }

          //console.log(this.editForm)
          this.$http.post('/finance/save_returnMoney', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
.edit /deep/.el-form-item__content{
  margin-left:0 !important;
}
.ele-block .el-upload-dragger {
  width: 100%;
}
.changeStyle{
  width:150px;margin-right:30px;
}
.ele-body{
  padding:15px;
}
.el-upload-list el-upload-list--text{display:none !important}
/deep/.el-tabs__item{
  padding:0 20px !important;
  font-size: 15px;
}
/deep/.el-tabs__nav-scroll{
  background: white !important;
  padding-top: 10px;
}
.boxTitle{padding:8px}
.orderImgBox{margin-left: 10px;height: 80px;}
.orderImgBox span{
  font-size: 36px;
}
.user-info-tabs >>> .el-tabs__nav-wrap {
  padding-left: 20px;
}
.innerBox{
  width:60%;
  margin: 0 auto;
  padding-top: 20px;
  border-bottom: 1px rgb(240,240,240) solid;
}
.innerBoxTo{
  width:60%;
  margin: 0 auto;
  padding-top: 20px;
}
.innerTitle{
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 13px;
}
.inputWidth{
  width:100%;
}
/deep/.el-dialog{
		margin-top: 30px !important;
		width: 50%;
	}
</style>






